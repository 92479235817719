<template>
  <div>
      <vue-header title="预约记录" rightName="去预约" :rightFun="()=>
          this.$router.push({
                name:'subscribe'
          })
      "/>
      <div class="record-box flex flex-y" v-for="( item, index ) in recordList" v-bind:key="index">
          <div class="record-title">
              {{item.sys_organizations.name}}
          </div>
          <div class="flex-1">
              <div>{{item.subscribe_date}} {{item.subscribe_time}}</div>
              <div v-if="item.status==1">预约中</div>
              <div v-if="item.status==2">已取消</div>
          </div>
          <div class="record-cancel" v-if="item.status==1 && !item.is_operator" @click="()=>cancelFun(item.id)">取消</div>
      </div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import { titleFun } from '@/utils';
import { mapActions , mapState } from 'vuex';
import { Dialog } from 'vant';
export default {
  name: 'record',
  components:{
    vueHeader
  },
  created:function(){
      this.recordMain();
      titleFun('预约记录');
  },
  computed:{
      ...mapState('my',['recordList']),
  },
  methods:{
    cancelFun(id){
        Dialog.confirm({
        title: '取消预约',
        message:
            '您真的要取消预约吗？',
        })
        .then(() => {
            this.recordCancelMain({
                id:id
            })
        })
    },
      ...mapActions('my',['recordMain','recordCancelMain'])
  },
}
</script>

<style lang="scss" scoped>
  @import './record';
</style>
